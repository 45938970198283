.sidebar-right-container {
  margin: 0;
  text-align: center;
  flex-basis: 25%;
  background-color: #edf8f4;
  display: flex;
  flex-direction: column;

  h3 {
    margin-top: 1rem;

    padding-bottom: 10px;
  }
}

@media all and (max-width: 1024px) {
  .sidebar-right-container h3 {
    display: none;
  }
}

@media all and (max-width: 900px) {
  .sidebar-right-container {
    display: none;
  }
}
