.footer-wrapper {
  background-image: linear-gradient(#4e9ecf, #4ea45f);
  width: 100% !important;
  margin: 0 auto;
  display: flex;
  padding: 20px 20px 60px 20px;

  .footer-wrapper-container {
    flex: 1;
  }

  hr {
    color: purple;
    margin: 0 auto;
    width: 80%;
  }

  p {
    margin-top: 20px;
    text-align: center;
    color: #fff;
  }

  #email-underline {
    text-decoration: underline !important;
  }

  a {
    color: #fff;
  }
}
