.sidebar-container {
  background-color: #edf8f4;
  width: 100%;
  padding: 20px;

  h5 {
    font-size: 0.3rem;
  }

  .sidebar-subtitle {
    margin-top: 0.8rem;
    text-decoration: none;
    transition: 0.4s all;

    &.active_item {
      color: black;
    }

    &:hover {
      font-size: 1.1rem;
    }
  }

  .sidebar-in {
    display: flex;
    justify-content: flex-start;

    ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none;

        svg {
          margin-top: 16px;
          margin-right: 6px;
        }
      }
    }
  }
}

@media all and (max-width: 1024px) {
  .sidebar-container {
    flex: 0.5;
    display: none;
  }
}
