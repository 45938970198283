@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.8;
  }
}

.slideshow-container {
  height: 100%;
  width: 100%;
  margin: 0px auto !important;
  display: flex;
  justify-content: center;

  .slideshow {
    width: 95%;
    // height: 30vw;
    position: relative;

    .controls {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:hover {
        .play-btn,
        .pause-btn {
          display: block;
        }
      }

      .arrow-btn {
        button {
          background: transparent;   
          border: none; 
          color: #fff;
          opacity: .6;
          
          svg {
            filter:drop-shadow(1px 1px 2px #000);
            width: 60px; 
            height: 60px; 
          }
        }
      }
    }

    

    .slideshow-image {
      width: 100%;
      height: 35vw;
    }

    .inactive {
      display: none;
    }
  }
}

@media all and (max-width: 900px) {

}